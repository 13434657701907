.screenMinWidth {
    min-Width: 800px;
}

.cardSize {
    width: 500px;
    min-Height: 380px;
    max-Height: 520px;
}

.schemaCardSize {
    width: 330px;
    min-Height: 120px;
    max-Height: 560px;
}

.schemaCardHeader{
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    width: 300px;
    max-width: 300px ;
    font-weight: bold;
}


.tooltipWidth {
    min-Width: 220px;
    text-wrap: nowrap;
}


@media only screen and (max-width: 768px) { 
    .gridWidth { 
        max-width: 800px;
        width: 800px;
    }
} 

.gridLargerWidth { 
    width: 1800px;
}


/* Media Query for Mobile Devices */ 
@media only screen and (max-width: 768px) { 
    .mobileOnly {
        font-size: large;
        visibility: visible;
        height: 40px;
    }    
} 

@media only screen and (max-width: 768px) {   
    .mobileOnlyIcon {
        visibility: hidden;
    }
}

@media only screen and (max-width: 4096px) {   
    .shiftRight {
        visibility: visible;
        position: fixed;
        top: 40px;
        left: 125px;
        height: 100%;
        width: 93svw;
        transition: all 0.3s ease;
    }
}

@media only screen and (max-width: 768px) {   
    .shiftRight {
        visibility: visible;
        position: fixed;
        top: 40px;
        left: 125px;
        height: 100%;
        width: 800px;
        transition: all 0.3s ease;
    }
}

.servicerunningbgcolour {
    background-color: #7fff2a !important;
    color: #000000 !important;
}

.servicedownbgcolour {
    background-color: #ff2a2a !important;
    color: #ffffff !important;
}

.studentbgcolour {
    background-color: #e0bbec !important;
}

.bothbgcolour {
    background-color: #cacaff !important;
}

.totalbgcolour {
    background-color: #000000 !important;
    color: #ffffff !important;
}

.styleLabelLarge {
    padding-Left: 2px;
    width: 240px;
    display: inline-block;
}
.styleLabel {
    padding-Left: 2px;
    width: 180px;
    display: inline-block;
}

.styleLabelLongCentre {
    padding-Left: 2px;
    display: inline-block;
    padding-Left: 2px;
    padding-top: 8px;
    font-weight: bold;
}

.styleLabelTop2 {
    padding-Left: 2px;
    width: 180px;
    min-width: 180px;
    display: inline-block;
    vertical-align: top;
}

.styleLabelMedium {
    padding-Left: 2px;
    padding-top: 8px;
    width: 140px;
    display: inline-block;
    vertical-align: top;
}

.styleLabel90px {
    padding-Left: 2px;
    padding-top: 8px;
    width: 90px;
    display: inline-block;
    vertical-align: top;
}

.styleLabelMediumTop {
    padding-Left: 2px;
    padding-top: 8px;
    width: 140px;
    display: inline-block;
    vertical-align: top;
}

.styleLabelSmall {
    padding-Left: 2px;
    padding-top: 8px;
    width: 80px;
    display: inline-block;
    /* vertical-align: middle; */
}

.styleLabelSmall50px {
    padding-Left: 2px;
    padding-top: 8px;
    width: 50px;
    display: inline-block;
    /* vertical-align: middle; */
}

.styleLabelSpecialShowAll {
    min-width: 70px;
    display: inline-block;
    text-align: left;
    vertical-align: middle;
    margin-top: 6px;
}

.styleLabelTop {
    padding-left: 2px;
    width: 180px;
    display: inline-block;
    vertical-align: top;
};

.styleLabelMiddle {
    padding-left: 2px;
    width: 180px;
    height: 40px;
    display: inline-block;
    vertical-align: middle;
};

.styleReadOnly {
    color: #92969a;
    font-weight: normal;
    font-size: 12px;
    margin-left: 8px;
    margin-bottom: 8px;
    padding-left: 12px;
    border-width: 0px ;
};

.styleDivHeight {
    margin-top: 1px;
    min-height: 48px;
};


.styleDivHeightFixed {
    margin-top: 1px;
    min-height: 48px;
};

.styleDivHeightFixedRO {
    margin-top: 1px;
    min-height: 64px;
};

.styleDivHeightFixedTall {
    margin-top: 1px;
    min-height: 100px;
};

.styleDivHeightSmallGap {
    height: 2px;
};

.styleDivHeightSmallROGap {
    height: 6px;
};

.stylePanelSmall {
    min-width: 240px;
    max-width: 240px;
};

.stylePanel {
    width: 300px;
    min-width: 240px;
    max-width: 400px;
};

.stylePanelLarger {
    width: 500px;
    min-width: 400px;
    max-width: 600px;
};

.stylePanelFixedHeight {
    width: 300px;
    min-width: 240px;
    max-width: 400px;
    height: 600px;
    min-height: 600px;
};

.styleButton  {
    transition: background-position 0.5s ease-out;
};

.headerStyleBG {
    background-color: #f8f9fa;
    height: 3em;
    border-style: solid;
    border-width: 1px;
    border-color: #dee2e6;
};
/* 
.headerStyleBG {
    background-color: #f8f9fa;
    height: 3.3em;
    border-style: solid;
    border-width: 1px;
    border-color: #dee2e6;
}; */

.headerStyle {
    margin-top: 4px;
    margin-right: 8px;
    background-color: #f8f9fa;
};

.headerTitleStyle {
    margin-top: 8px;
}

.styleCheckbox {
    border-width: 1px;
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 4px;
    vertical-align: middle;
};

.styleEdit {
    min-width: 200px;
    width: 250px;
    border-width: 1px;
    font-weight: bold;
    font-size: 12px;
    margin-bottom: 4px;
    vertical-align: middle;
};

.inputNormalWidth {
    max-width: 200px;
}

.zOrderPlus1 {
    z-index: 2;
}

.sidebar {
    position: fixed;
    top: 0;
    left: 75px;
    height: 100%;
    width: 280px;
    padding: 10px 14px;
    background: #fff;
    transition: all 0.3s ease;
    z-index: 100;
    border-width: 1px;
    border-color: #dee2e6;
    border-style: solid;
}

.sidebar .sidebarCriteria{
    transition: all 0.2s ease;
}

.sidebarCriteria.close{
    visibility: hidden;
    transition: all 0.2s ease;
}

.sidebar .text,
.sidebar .icon{
    color: #333;
    transition: all 0.2s ease;
}

.sidebar .text{
    text-align: center;
}

.sidebar.close .text{
    text-align: center;
    visibility: hidden;
}

.menu-bar {
    visibility: visible;
    position: fixed;
    top: 40px;
    left: 14;
    height: 100%;
    width: 240px;
    background:#fff;
    vertical-align: middle;
}

.menu-bar.close {
    visibility: hidden;
}

.myHeader .name {
    margin-top: 2px;
    font-size: 14px;
    font-weight: 900;
    text-align: center;
}

.sidebar .icon{
    min-width: 60px;
    border-radius: 6px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
}

.myHeader .toggle{
    position: absolute;
    top: 16px;
    right: 5px;
    transform: translateY(-50%) rotate(180deg);
    height: 25px;
    width: 25px;
    background-color: #695cfe;
    color: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.sidebar.close{
    transition: all 0.3s ease;
    width: 40px;
}
 
.sidebar.close .toggle{
    transform: translateY(-50%) rotate(0deg);
}

.sideMenuFullHeight {
    height: 90%;
}

.shiftMeRight {
    left: 370px;
    width: 77svw;
    transition: all 0.3s ease;
}

.mr-0 {
    margin-right: 0;
}

.ml-auto {
    margin-left:auto;
}

.d-block {
    display:block;
}

.fontBold {
    font-weight: bold;
}

.collapsible .content {
    padding: 6px;
    border-style: solid;
    border-width: 1px;
    border-color: #dee2e6;
    /* background-color: rgb(240, 240, 240); */
    /* font-size: 1.5rem; */
}
.collapsible .header {
    background-color: #f8f9fa;
    max-height: 32px;
    padding: 6px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    border-width: 1px;
    border-color: #dee2e6;
    border-style: solid;
    margin-top: 2px;
    /* border-bottom: 2px solid rgb(220, 220, 220); */
}
.collapsible .header .title {
    font-weight: bold;
    font-size: 1.25rem;
}

pre {
    white-space: pre-line;
  }

.colorRed {
    color: red;
}
.colorOrange {
    color: orange;
}

.colorBlue {
    color: rgb(26, 26, 177);
}

.iconSizeX {
    font-size: 1.8rem;
}

.inputTextWidthL {
    width: 400px;
}

.legendWidth{
    width: 400px;
}

.cardTitle {
    font-size:medium;
    font-weight: bold;
    vertical-align: text-bottom;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 5px;

}

.cardSubTitle {
    font-size:medium;
    font-weight:normal;
    vertical-align: text-bottom;
    margin-left: 10px;
    margin-right: 10px;
}

.cardRight {
    font-size:small;
    font-weight:normal;
    vertical-align: text-bottom;
    margin-right: 10px;
    color: cornflowerblue;
}

.cardRightBold {
    font-size:small;
    font-weight:bold;
    vertical-align: text-bottom;
    margin-right: 10px;
}

.cardDetailsBold {
    font-size:small;
    font-weight:bold;
    overflow: hidden;
      text-overflow: ellipsis;
}

.cardDetailsBoldRed {
    font-size:small;
    font-weight:bold;
    color: crimson;
}

.cardDetailsBoldGreen500 {
    font-size:small;
    font-weight:bold;
    color: darkgreen;
    opacity: 0.75;
}
.cardDetailsBoldRed500 {
    font-size:small;
    font-weight:bold;
    color: crimson;
    opacity: 0.75;
}

.cardDetailsBoldOrange500 {
    font-size:small;
    font-weight:bold;
    color: orange;
    opacity: 0.75;
}

.cardBoldGreen500 {
    font-size:smaller;
    font-weight:bold;
    color: darkgreen;
    opacity: 0.75;
}
.cardBoldRed500 {
    font-size:smaller;
    font-weight:bold;
    color: crimson;
    opacity: 0.75;
}

.cardBoldOrange500 {
    font-size:smaller;
    font-weight:bold;
    color: orange;
    opacity: 0.75;
}

.cardDetailsBoldSmaller {
    font-size:smaller;
    font-weight:bold;
    overflow: hidden;
    text-overflow: ellipsis;
}

.cardSize {
    width: 500px;
    min-Height: 300px;
    max-height: 520px;
}

.iconsize64 {
    width: 32px !important;
    height: 32px !important;
}

.miniCardSize {
    width: 250px;
    min-Height: 150px;
    max-height: 260px;
}

.macroCardSize {
    width: 250px;
    min-Height: 100px;
    max-height: 130px;
}

.miniCardTitle {
    font-size:8px;
    font-weight: bold;
    /* vertical-align: text-bottom; */
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 5px;
}

.miniCardSmall {
    width: 50px;
    white-space: nowrap;
    font-size:8px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.detailCardTextSmall {
    white-space: nowrap;
    font-size:11px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.detailCardTextSmallBold {
    white-space: nowrap;
    font-size:11px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: bold;
}

.miniCardSubTitle {
    font-size:8px;
    font-weight:normal;
    vertical-align: text-top;
    margin-left: 10px;
    margin-right: 10px;
}

.miniCardRight {
    font-size:8px;
    font-weight:normal;
    vertical-align: text-bottom;
    margin-right: 10px;
    color: cornflowerblue;
}

.miniCardRightBold {
    font-size:8px;
    font-weight:bold;
    vertical-align: text-bottom;
    margin-right: 10px;
}

.miniCardDetailsBold {
    font-size:8px;
    font-weight:bold;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display:inline-block;
    width: 150px; 
}

.miniCardDetailsBoldRed {
    font-size:smaller;
    font-weight:bold;
    color: crimson;
}

.miniCardDetailsBoldGreen500 {
    font-size:smaller;
    font-weight:bold;
    color: darkgreen;
    opacity: 0.75;
}
.miniCardDetailsBoldRed500 {
    font-size:smaller;
    font-weight:bold;
    color: crimson;
    opacity: 0.75;
}

.miniCardDetailsBoldOrange500 {
    font-size:smaller;
    font-weight:bold;
    color: orange;
    opacity: 0.75;
}

.miniCardBoldGreen500 {
    font-size:x-small;
    font-weight:bold;
    color: darkgreen;
    opacity: 0.75;
}
.miniCardBoldRed500 {
    font-size:x-small;
    font-weight:bold;
    color: crimson;
    opacity: 0.75;
}

.miniCardBoldOrange500 {
    font-size:x-small;
    font-weight:bold;
    color: orange;
    opacity: 0.75;
}

.miniCardDetailsBoldSmaller {
    font-size: 6px;
    font-weight:bold;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display:inline-block;
    width: 150px; 
}

.miniIconSize {
    width: 32px;
}

.cardDetailsTitles {
    font-size:small;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tooltip_element {
    position: relative;
  }
  
.tooltip {
    position: absolute;
    background-color: black;
    color: white;
    padding: 5px;
    border-radius: 5px;
    opacity: 0;
    transition: opacity 1s;
    top: 0;
    left: 80px;
    width: max-content;
  }
  
  .tooltip_element:hover .tooltip {
    opacity: 1;
  }

  .smallDialogHeaderText {
    font-size: 12px !important;
  }

 .item {
    position:relative;
    padding-top:0px;
    display:inline-block;
    margin-top: 1px;
    min-height: 22px;
}

.notify-badge{
    position: absolute;
    right:170px;
    top:-10px;
    background:red;
    text-align: center;
    border-radius: 30px 30px 30px 30px;
    color:white;
    padding:5px 10px;
    font-size:20px;
    opacity: 0.5;
}