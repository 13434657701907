@font-face {
  font-family: "KxFont";
  src: local("KxFont"),
   url("./fonts/KxFont.ttf") format("truetype");
  font-weight: normal;
}

.font-face-kx {
  font-family: "KxFont";
}

html {
  font-size: 12px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.mysidenav {
  background-color: #2E4680 !important;
  font-family: 'Segoe UI', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
  color: black !important;
}

.page {
  font-size: 10;
}

.pageTitle {
  font-size: 32;
  font-weight: bold;
  text-align: center;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  margin: 0;
  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  background: #ffffff;
  font-size: 14px;
}
.app {
  background: #a8d5e5;
}
h1 {
  color: #165a72;
  margin: 50px auto;
  font-size: 40px;
}
.menu1 {
  margin-bottom: 40px;
  margin-top: 20px;
}
h2 {
  color: #165a72;
}

.container-comp {
  text-align:center;
  flex-grow: 1;
}

body .p-panel .p-panel-content {
  border-top: 0 none;
}

body .p-float-label {
  font-weight: bold;
}
