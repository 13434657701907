.datatable-scroll-demo .p-datatable-frozen-tbody {
    font-weight: bold;
}

.datatable-scroll-demo .p-datatable-scrollable .p-frozen-column {
    font-weight: bold;
}

.searchButton {
    min-width: 150px;
    max-width: 150px;
}

.searchButtonOuter {
    max-width: 200px;
}

.ColumnSelector {

    max-height: 6em;
    font-size: 0.5em;
    max-width: 150px;
}

.clearSearchButton {
    border-color: #e9ecef;
}

.gridSelection {
    color: #646eec;
    
}

.gridSelection:hover {
    text-decoration: underline;
    color: #646eec;
    
}

.normalText {
    font-weight: normal;
}

.headerStyleBG {
    background-Color: #f8f9fa;
    height: 3em;
    border-style: solid;
    border-width: 1px;
    border-color: #dee2e6;
};

.headerStyle {
    margin-top: 4px;
    margin-right: 8px;
    background-color: #f8f9fa;
};

.headerTitleStyle {
    margin-top: 8px;
}

.headerTitleStyle {
    margin-top: 8px;
}

.stylePanelGrid {
    min-width: 200px;
    margin-left: 2px;
}

.styleSearchHeader {
    max-height: 2.5em;
}

.hyperlinkMaxWidth{
    max-width: 250px;
}


.gridHyperlink {
    color: #646eec;
    
}

.gridHyperlinkPrimaryKey {
    color: #f12334;
    font-weight: bold;
    
}

.gridHyperlinkPrimaryKey:hover {
    text-decoration: underline;
    color: #f12334;
    
}

.gridHyperlinkPrimaryLinkedKey {
    color: #1905ce;
    
}

.gridHyperlinkForeignKey {
    font-style: italic;
    color: #2623f1;
    
}

.gridHyperlinkForeignKey:hover {
    text-decoration: underline;
    color: #2623f1;
    
}

.gridHyperlinkForeignKeyAdmin {
    font-weight: bold;
    font-weight: bold;
}

.gridHyperlinkForeignKeyAdmin:hover {
    text-decoration: underline;
    font-weight: bold;
}

.gridHyperlink:hover {
    text-decoration: underline;
    color: #646eec;
    
}

.gridHyperlinkSelected {
    color: #646eec;
    text-decoration: underline;
    font-weight: bolder;
}

.tableTallRows .p-datatable-tbody > tr > td {
    height: 48px;
}




  